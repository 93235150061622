<template>
  <file-manager
    v-if="hasFiles"
    :query-config="queryConfig"
    :selection.sync="value.files"
    :readonly="true"
    :no-quick-display="false"
    dense
    ref="file-manager"
  />
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  components: {
    FileManager: () => import('@/components/file-manager')
  },
  computed: {
    queryConfig () {
      if (!this.value.dataSourceFolder) {
        return undefined
      }
      return { dataSourceFolder: this.value.dataSourceFolder }
    },
    hasFiles () {
      return this.value.files?.length
    }
  },
  props: {
    value: {
      type: Object
    },
    column: Column
  }
}
</script>
